/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, EventAttachment
} from '../dto';

import { EventType } from './eventtype';

export interface CoverageGoal_v1 extends DTO, DTOMethods<CoverageGoal_v1> {
    eventType: EventType;
    startDate: Date;
    endDate: Date;
    status: string;
    priority: number;
    rank: number;
    createdDate: Date;
    completedDate: Date;
    rag: string;
    progress: number;
    duration: number;
    durationType: string;
    durationUnit: string;
    isReadOnly: boolean;
    licensing: string;
    dynatraceEnvironment: DTORef;
    children: DTORef[];
    files: EventAttachment[];
    resolvedUsers: Attachment[];
}
export const CoverageGoal_v1: DTOMetaData & DTOClassMethods<CoverageGoal_v1> = {
    _label:'Coverage Goals',
    _dto: 'CoverageGoal_v1',
    _type: 'coverage-goal',
    _colors: { primaryThemeColor: "#6d9b44", secondaryThemeColor: "#558a1f", tertiaryThemeColor: "#558a1f" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Event type', prop: 'eventType', hint: 'Event type', type: 'enum', fixed: true, source: undefined, values: ["COVERAGE_GOAL"], default: EventType.GOAL },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Status', prop: 'status', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "New" },
        { label: 'Priority', prop: 'priority', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Rank', prop: 'rank', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Created date', prop: 'createdDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Completed date', prop: 'completedDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Rag', prop: 'rag', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Progress', prop: 'progress', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration', prop: 'duration', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration type', prop: 'durationType', hint: 'Duration type', type: 'enum', fixed: false, source: undefined, values: ["FIXEDDURATION", "FIXEDEND"], default: "FIXEDEND" },
        { label: 'Duration unit', prop: 'durationUnit', hint: 'Duration unit', type: 'enum', fixed: false, source: undefined, values: ["HOUR", "DAY", "WEEK", "MONTH", "QUARTER", "YEAR"], default: "DAY" },        { label: 'Is read only', prop: 'isReadOnly', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Licensing', prop: 'licensing', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Dynatrace environment', prop: 'dynatraceEnvironment', hint: 'Dynatrace environment', type: 'dto', fixed: true, source: '/', values: undefined, default: undefined },
    ],
    _related: [
        { prop: 'children', type: 'coverage-objective', class: 'CoverageObjective_v1', label: 'Objectives' },
    ],
    _childrenAt: 'children',
    _endpoint: '/api/eda/v2.0/coverage-goal/v1.0',
    _docLink: '/wiki/elevate/Assets/coverage-goal/',
    _icon: './assets/dtos/coverage-goal.svg',

    _depth: 1,
    endpointAPI: new BoundEndpointAPI<CoverageGoal_v1>('CoverageGoal_v1', DTO),
    from: (obj: any): CoverageGoal_v1 => {
        return Object.setPrototypeOf(obj, CoverageGoal_v1._prototype);
    },
    select: (p: any = ''): any => {
        return CoverageGoal_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => CoverageGoal_v1.from(a) as CoverageGoal_v1)
            : CoverageGoal_v1.from(res)
    )}
}
